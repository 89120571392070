<template>
  <div>
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img alt="" class="w-6" :src="require(`@/assets/images/logo.svg`)" />
            <span class="text-white text-lg ml-3">
              <span class="font-medium">智莺支付联盟</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt=""
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
           智莺支付联盟助你一键接入<br />
         支付宝、微信、各大优惠活动 

            </div> 
            <!-- <div class="-intro-x mt-5 text-lg text-white">
            商家，门店，社区团购，直播进入数字营销时代 
            </div> -->
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
              登录
            </h2>
            <!-- <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
              A few more clicks to sign in to your account. Manage all your
              e-commerce accounts in one place
            </div> -->
            <div class="intro-x mt-8">
              <input
                v-model="phone"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="请输入手机号码"
              />
              <input
                v-model="psw"
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="请输入密码"
                show-password
              />
            </div>
            <div class="intro-x flex text-gray-700 text-xs sm:text-sm mt-4">
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  v-model="isKeep"
                  type="checkbox"
                  class="input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >记住登录</label
                >
              </div>
              <span class="cursor-pointer" @click="onRetrieve">忘记密码?</span>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
                @click="onLogin"
              >
                登录
              </button>
              <button
                class="button button--lg w-full xl:w-32 text-gray-700 border border-gray-300 mt-3 xl:mt-0 align-top"
                @click="onRegister"
              >
                注册
              </button>
            </div>
            <div class="intro-x mt-10 xl:mt-24 text-gray-700 text-center xl:text-left">
              通过登录，你就同意我们的 <br />
              <a class="text-theme-1" href="">条款和条件</a>
              &
              <a class="text-theme-1" href="">隐私政策</a>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import request from "../utils/request";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      psw: "",
      phone: "",
      isKeep: false,
    };
  },
  mounted() {
    cash("body").removeClass("app").addClass("login");
    this.init();
  },

  methods: {
    init() {
      if (Cookies.get("account")) {
        let data = JSON.parse(Cookies.get("account"));
        this.phone = data.phone;
        this.psw = data.psw;
        this.isKeep = data.isKeep;
      }
    },

    onLogin() {
      if (this.phone === "") {
        this.$Toastify({
          node: cash(`<span>手机号不能为空!</span>`)[0],
          duration: 3000,
          newWindow: true,
          backgroundColor: "#FBC500",
          close: true,
          stopOnFocus: true,
        }).showToast();

        return;
      }
      if (this.psw === "") {
        this.$Toastify({
          node: cash(`<span>密码不能为空!</span>`)[0],
          duration: 3000,
          newWindow: true,
          backgroundColor: "#FBC500",
          close: true,
          stopOnFocus: true,
        }).showToast();
        return;
      }

      request({
        url: "/login/login",
        method: "POST",
        data: {
          phone: this.phone,
          psw: this.psw,
        },
      }).then((res) => {
        if (res.code === 0) {

          delete res.data.userInfo.saasAppList
          Cookies.set("accessToken",res.data.token.accessToken, { expires: 7 });
          Cookies.set("userInfo", JSON.stringify(res.data.userInfo), { expires: 7 });
 
          if (this.isKeep) {  
            Cookies.set("account", JSON.stringify({ phone: this.phone, psw: this.psw, isKeep: this.isKeep,}), { expires: 7 }); // 记住账号密码7天后过期
          } else {
            Cookies.remove("account");
          }
          this.$Toastify({
            node: cash(`<span>登录成功。</span>`)[0],
            duration: 3000,
            newWindow: true,
            close: true,
            stopOnFocus: true,
          }).showToast();
          this.$router.push("/");
        } else {
          this.$Toastify({
            node: cash(`<span style='color:#D32929'>登录失败。</span>`)[0],
            duration: 3000,
            newWindow: true,
            close: false,
            stopOnFocus: true,
            backgroundColor: "#ffd8d8",
          }).showToast();
        }
      });
    },
    onRegister() {
      this.$router.push("/register");
    },

    onRetrieve() {
      this.$router.push("/retrieve-password");
    },
  },
};
</script>
